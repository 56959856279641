.footer {
	background: $white;
	border-top: 1px solid $border;
	font-size: .875rem;
	padding: 1.25rem 0;
	color: #6987ab;
	.social {
		ul li {
			float: left;
			padding: 7px;
			a {
				color: #6987ab;
			}
		}
		margin: 0 auto;
		width: auto;
	}
	a:not(.btn) {
		color: $primary;
	}
}

@media print {
	.footer {
		display: none;
	}
}

.footerimg img {
	width: 35px;
	height: 35px;
}

.footer-main .social li {
	float: left;
	margin-right: 15px;
	display: inline-block;
	list-style: none;
	font-size: 20px;
	a {
		color: #6987ab;
		line-height: 0;
	}
}

.social li {
	float: left;
	margin-right: 15px;
	display: inline-block;
	list-style: none;
	font-size: 15px;
	float: right;
}

.social-icons li {
	a {
		color: $white;
	}
	margin-right: 15px;
	display: inline-block;
	list-style: none;
	font-size: 15px;
	width: 40px;
	height: 40px;
	margin: 1px;
	border-radius: 50%;
	border: 1px solid $white-2;
	line-height: 40px;
}

.social li a {
	color: $white-6;
	line-height: 0;
}

.footer-main {
	.payments li {
		float: left;
		margin-right: 15px;
		display: inline-block;
		list-style: none;
		font-size: 20px;
		a {
			color: $white-6;
			line-height: 0;
		}
	}
	h6 {
		font-size: 18px;
		margin-bottom: 1.2rem;
	}
}

.footer .border-bottom {
	border-bottom: 1px solid $white-2 !important;
}

.footer-main {
	p {
		color: $white-7;
	}
	a {
		line-height: 2;
		transition: opacity .2s;
		color: $white-7;
	}
	&.footer-main1 {
		a {
			color: #2e384d;
		}
		.form-control {
			border: 1px solid $border !important;
		}
	}
}

.footer-links a {
	color: $white-8;
	&:before {
		content: '\e049';
		margin-right: 5px;
		position: relative;
		font-family: 'typicons' !important;
		opacity: 0.5;
	}
	&:hover {
		text-decoration: none;
	}
}

@media (max-width: 992px) {
	.footer-main h6 {
		margin-top: 2rem;
	}
	.footer.bg-dark {
		.text-left {
			text-align: center !important;
		}
		.social {
			li {
				float: none;
				text-align: center;
			}
			text-align: center;
		}
	}
	.footer-main .row div:first-child h6 {
		margin-top: 0;
	}
}

.footer-main {
	padding-top: 3rem;
	padding-bottom: 3rem;
	border-top: 1px solid $white-2;
	border-bottom: 1px solid $white-2;
}

footer .border-top {
	border-top: 1px solid $white-2 !important;
}

.footer-main .form-control {
	border: 0 !important;
}

.footer-payments {
	padding-left: 0;
	list-style: none;
	margin: 0;
	line-height: 1.25rem;
	li {
		display: inline-block;
		padding: 0 11px;
		font-size: 20px;
	}
}

.footer-main img {
	width: 80px;
	border: 1px solid $white-1;
	padding: 2px;
}

@media (min-width: 768px) {
	.footer.footer-style {
		margin-left: 0px !important;
	}
}

footer.bg-dark .border-bottom {
 border-bottom: 1px solid $white-1 !important
}