.jq-stars {
	display: inline-block;
  }
  
  .jq-rating-label {
	font-size: 22px;
	display: inline-block;
	position: relative;
	vertical-align: top;
	font-family: helvetica, arial, verdana;
  }
  
  .jq-star {
	width: 100px;
	height: 100px;
	display: inline-block;
	cursor: pointer;
  }
  
  .jq-star-svg {
	padding-left: 3px;
	width: 100%;
	height: 100%;
  }
  
  .jq-star:hover .fs-star-svg path {}
  
  .jq-star-svg path {
	/* stroke: #000; */
	stroke-linejoin: round;
  }
  
  /* un-used */
  
  .jq-shadow {
	-webkit-filter: drop-shadow(-2px -2px 2px #888);
	filter: drop-shadow(-2px -2px 2px #888);
  }