.typing_loader {
	width: 6px;
	height: 6px;
	border-radius: 50%;
	-webkit-animation: typing 1s linear infinite alternate;
	-moz-animation: typing 1s linear infinite alternate;
	-ms-animation: typing 1s linear infinite alternate;
	animation: typing 1s linear infinite alternate;
	position: relative;
	left: -12px;
	margin: 7px 15px 6px;
  }
  
  .to .typing_loader {
	animation: typing-black 1s linear infinite alternate;
  }
  
  @-webkit-keyframes typing {
	0% {
	  background-color: rgba(255, 255, 255, 1);
	  box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.4), 24px 0px 0px 0px rgba(255, 255, 255, 0.2);
	}
  
	50% {
	  background-color: rgba(255, 255, 255, 0.4);
	  box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 1), 24px 0px 0px 0px rgba(255, 255, 255, 0.4);
	}
  
	100% {
	  background-color: rgba(255, 255, 255, 0.2);
	  box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.4), 24px 0px 0px 0px rgba(255, 255, 255, 1);
	}
  }
  
  @-moz-keyframes typing {
	0% {
	  background-color: rgba(255, 255, 255, 1);
	  box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.4), 24px 0px 0px 0px rgba(255, 255, 255, 0.2);
	}
  
	50% {
	  background-color: rgba(255, 255, 255, 0.4);
	  box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 1), 24px 0px 0px 0px rgba(255, 255, 255, 0.4);
	}
  
	100% {
	  background-color: rgba(255, 255, 255, 0.2);
	  box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.4), 24px 0px 0px 0px rgba(255, 255, 255, 1);
	}
  }
  
  @keyframes typing-black {
	0% {
	  background-color: rgba(74, 74, 74, 1);
	  box-shadow: 12px 0px 0px 0px rgba(74, 74, 74, 0.4), 24px 0px 0px 0px rgba(74, 74, 74, 0.2);
	}
  
	50% {
	  background-color: rgba(74, 74, 74, 0.4);
	  box-shadow: 12px 0px 0px 0px rgba(74, 74, 74, 1), 24px 0px 0px 0px rgba(74, 74, 74, 0.4);
	}
  
	100% {
	  background-color: rgba(74, 74, 74, 0.2);
	  box-shadow: 12px 0px 0px 0px rgba(74, 74, 74, 0.4), 24px 0px 0px 0px rgba(74, 74, 74, 1);
	}
  }
  
  @keyframes typing {
	0% {
	  background-color: rgba(255, 255, 255, 1);
	  box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.4), 24px 0px 0px 0px rgba(255, 255, 255, 0.2);
	}
  
	50% {
	  background-color: rgba(255, 255, 255, 0.4);
	  box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 1), 24px 0px 0px 0px rgba(255, 255, 255, 0.4);
	}
  
	100% {
	  background-color: rgba(255, 255, 255, 0.2);
	  box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.4), 24px 0px 0px 0px rgba(255, 255, 255, 1);
	}
  }
  
  form.convFormDynamic {
	margin: 10px 10px;
	padding: 0 !important;
	position: relative;
	border: 1px solid $border;
	border-radius: 3px;
  
	textarea.userInputDynamic, input.userInputDynamic {
	  border: none;
	  padding: 7px 10px;
	  outline: none;
	  font-size: 0.905rem;
	  float: left;
	  width: calc(100% - 70px);
	  line-height: 1.3em;
	  min-height: 1.7em;
	  max-height: 10rem;
	  display: block;
	  max-width: 88%;
	  margin-right: 2.5%;
	}
  }
  
  div {
	&.conv-form-wrapper {
	  div {
		&#messages {
		  max-height: 71vh;
		  height: auto !important;
		  overflow-y: scroll;
  
		  &:after {
			content: '';
			display: table;
			clear: both;
		  }
		}
  
		&.wrapper-messages {
		  position: relative;
		  height: 577px;
		  max-height: 71vh;
		  overflow-y: scroll;
		}
	  }
  
	  position: relative;
  
	  &:before {
		content: '';
		position: absolute;
		width: 100%;
		display: block;
		height: 30px;
		top: 0;
		left: 0;
		z-index: 2;
		background: linear-gradient(#fff, transparent);
	  }
  
	  div.wrapper-messages::-webkit-scrollbar {
		width: 0px;
		height: 0px;
  
		/* remove scrollbar space */
		background: transparent;
  
		/* optional: just make scrollbar invisible */
	  }
	}
  
	&#feed ul::-webkit-scrollbar, &.conv-form-wrapper div.options::-webkit-scrollbar {
	  width: 0px;
	  height: 0px;
  
	  /* remove scrollbar space */
	  background: transparent;
  
	  /* optional: just make scrollbar invisible */
	}
  }
  
  @media (max-width: 767px) {
	div.conv-form-wrapper div {
	  &.wrapper-messages, &#messages {
		max-height: 71vh;
	  }
	}
  }
  
  input[type="text"].userInputDynamic, textarea.userInputDynamic {
	&.error {
	  color: #ac0000 !important;
	}
  
	border-radius: 3px;
	margin: 7px 10px;
  }
  
  div.conv-form-wrapper div {
	&#messages {
	  transition: bottom 0.15s, padding-bottom 0.15s;
	  position: absolute;
	  bottom: 0;
	  height: auto !important;
	  width: 100%;
	  padding-bottom: 20px;
  
	  /*max-height: 71vh;*/
	}
  
	&.options {
	  word-wrap: normal;
	  white-space: nowrap;
	  overflow-x: scroll;
	  position: absolute;
	  bottom: 100%;
	  width: 100%;
	  transform: translateY(-5px);
  
	  &:after {
		content: '';
		display: table;
		clear: both;
	  }
  
	  div.option {
		padding: 7px 12px;
		display: inline-block;
		margin: 5px;
		background: #fff;
		cursor: pointer;
		border-radius: 3px;
		font-size: 0.9rem;
	  }
	}
  
	&.message {
	  animation: slideTop 0.15s ease;
  
	  &:after {
		content: '';
		display: table;
		clear: both;
	  }
  
	  &.ready {
		animation: bounceIn 0.2s ease;
		transform-origin: 0 0 0;
	  }
	}
  
	&#messages div.message {
	  border-radius: 20px;
	  padding: 12px 22px;
	  font-size: 0.905rem;
	  display: inline-block;
	  padding: 10px 15px 8px;
	  border-radius: 20px;
	  margin-bottom: 5px;
	  float: right;
	  clear: both;
	  max-width: 65%;
	  word-wrap: break-word;
  
	  &.to {
		float: left;
		border-top-left-radius: 0;
	  }
  
	  &.from {
		color: #fff;
		border-top-right-radius: 0;
	  }
	}
  }
  
  .message {
	&.to + .message.from, &.from + .message.to {
	  margin-top: 15px;
	}
  }
  
  @keyframes slideTop {
	0% {
	  margin-bottom: -25px;
	}
  
	100% {
	  margin-bottom: 0;
	}
  }
  
  @keyframes bounceIn {
	0% {
	  transform: scale(0.75, 0.75);
	}
  
	100% {
	  transform: scale(1, 1);
	}
  }
  
  div.conv-form-wrapper div.options div.option {
	&:hover {
	  background: $background;
	}
  
	&.selected:hover {
	  background: $primary;
	}
  }
  
  form.convFormDynamic button.submit {
	padding: 3px;
	border: none;
	float: right;
	margin: 5px;
	color: #fff;
	cursor: pointer;
	border-radius: 3px;
	font-size: 1.1rem;
	width: 36px;
	height: 35px;
	outline: none !important;
  }
  
  .center-block {
	margin-right: 0;
	margin-left: 0;
	float: none;
	text-align: center;
  }
  
  button.submit.glow {
	box-shadow: none;
  }
  
  .no-border {
	border: none !important;
  }
  
  .dragscroll {
	cursor: grab;
  }
  
  div {
	&.conv-form-wrapper div#messages::-webkit-scrollbar, &#feed ul::-webkit-scrollbar {
	  width: 0px;
  
	  /* remove scrollbar space */
	  background: transparent;
  
	  /* optional: just make scrollbar invisible */
	}
  }
  
  span.clear {
	display: block;
	clear: both;
  }