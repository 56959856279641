.rating-stars {
	.rating-stars-container {
		font-size: 0;
		.rating-star {
			display: inline-block;
			font-size: 32px;
			cursor: pointer;
			padding: 5px 8px;
			color: #ebeefb;
			&.sm {
				display: inline-block;
				font-size: 14px;
				color: #83829c;
				cursor: pointer;
				padding: 1px;
			}
			&.is--active,
			&.is--hover {
				color: #f1c40f;
			}
			&.is--no-hover,
			.fa-heart .is--no-hover {
				color: #3e4b5b;
			}
		}
	}
	input {
		display: none;
		margin: 0 auto;
		text-align: center;
		padding: .375rem .75rem;
		font-size: .9375rem;
		line-height: 1.6;
		color: #3d4e67;
		background-color: $white;
		background-clip: padding-box;
		border: 1px solid $border;
		border-radius: 3px;
		transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	}
	&.star input {
		display: none;
	}
}