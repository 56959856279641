.position-static {
	position: static !important;
}

.position-relative {
	position: relative !important;
}

.position-absolute {
	position: absolute !important;
}

.position-fixed {
	position: fixed !important;
}

.position-sticky {
	position: -webkit-sticky !important;
	position: sticky !important;
}

.z-index-0 {
	z-index: 0;
  }